@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Solway:wght@400;700;800&display=swap");

#location {
  --primary: #e2ca5d;
  --primaryLight: #415b44;
  --secondary: #a36700;
  --secondaryLight: #e5dece;
  --headerColor: #e5dece;
  --bodyTextColor: #4e4b66;
  --bodyTextColorWhite: #fafbfc;
  --topperFontSize: clamp(0.8125rem, 1.6vw, 1rem);
  --headerFontSize: clamp(1.9375rem, 3.9vw, 3.0625rem);
  --bodyFontSize: 1rem;
  --sectionPadding: clamp(3.75rem, 7.82vw, 6.25rem) 1rem;
}

#location {
  font-family: "Solway", serif;
  padding: var(--sectionPadding);
}

#location .location-container {
  width: 100%;
  max-width: 80rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: clamp(3rem, 6vw, 4rem);
}

#location .location-title {
  font-size: var(--headerFontSize);
  font-weight: 900;
  line-height: 1.2em;
  text-align: inherit;
  max-width: 24ch;
  margin: 0 0 1rem 0;
  color: var(--headerColor);
  position: relative;
}

#location .location-content {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#location .location-content h3 {
  color: red;
  font-size: large;
  width: 400px;
}

#location .location-topper {
  font-size: var(--topperFontSize);
  line-height: 1.2em;
  text-transform: uppercase;
  text-align: inherit;
  letter-spacing: 0.1em;
  font-weight: 700;
  color: var(--primary);
  margin-bottom: 0.25rem;
  display: block;
}

.location-text-container {
  width: 90%;
  max-width: 750px;
  color: var(--headerColor);
  font-weight: 700;
  text-align: justify;
}

.map-text-container {
  width: 90%;
  max-width: 750px;
  color: var(--headerColor);
  font-weight: 700;
  text-align: justify;
}

.location-text-container h1 {
  margin-top: 50px;
  text-align: center;
  color: var(--primary);
}

.icon-text {
  text-decoration: none;
  color: var(--headerColor);
}

.icon-container {
  font-size: 40px;
  color: var(--headerColor);
  font-weight: 700;
}

.icon-text:hover {
  color: var(--primary);
}

.icon:hover {
  color: var(--primary);
}

.icon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  max-width: 750px;
  margin: 0 auto;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.location-flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.location-img-container {
  max-width: 750px;
}

.location-img-container img {
  width: 100%;
  height: auto;
}

.the-map {
  border: none;
  width: clamp(400px, 90%, 950px);
  height: 500px;
  border-radius: 5px;
}

.drag-map {
  color: var(--primary);
  font-weight: 600;
  margin-bottom: -20px;
}

.the-seasons-text {
  text-align: center;
  color: var(--headerColor);
}

.the-seasons-text h1 {
  font-weight: 700;
  font-size: xx-large;
}

.the-seasons-text p {
  width: 700px;
  font-weight: 700;
  margin-left: 20px;
}

@media screen and (max-width: 250px) {
  .icon-container {
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  /* MOBILE */

  .nashville-map-container img {
    margin-top: 10px;
    width: 40%;
    height: auto;
    margin-left: 20px;
  }

  .icon {
    width: 30%;
  }

  .icon-container {
    font-size: xx-large;
    margin-top: 40px;
  }
  
  .the-seasons-text h1 {
    font-size: x-large;
    margin-top: -10px;
  }
  
  .the-seasons-text p {
    width: 300px;
    
  }
}

@media only screen and (min-width: 601px) {
  /* DESKTOP */

  .location-text-container h1 {
    margin-top: 50px;
    text-align: center;
    font-size: xx-large;
  }

  .icon-flex-container {
    width: 100%;
  }
}

@media screen and (max-width: 1299px) {
  .location-flex-container {
    flex-direction: column;
  }

  .location-img-container {
    width: 90%;
  }

  .nashville-map-container img {
     width: 90%;
     max-width: 450px;
     height: auto;
   }
 
   .nashville-map {
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
   }

   .nashville-text-container {
     width: 90%;
   }
}

@media screen and (min-width: 1300px) {
  .location-flex-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .location-img-container {
    width: 45%;
  }

  .location-text-container {
    width: 45%;
  }

  .nashville-map-container img {
    width: 90%;
    max-width: 450px;
    height: auto;
  }

  .nashville-map {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .map-text-container {
     width: 50%;
  }

  .icon-container {
     max-width: none;
  }
}
