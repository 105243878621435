@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Solway:wght@400;700;800&display=swap');

.headerImg img {
     width: 100%;
     aspect-ratio: 8/2;
}

.modal-title {
     font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media only screen and (min-width: 0rem) {
     #hp {
          --primary: #e2ca5d;
          --primaryLight: #415b44;
          --secondary: #a36700;
          --secondaryLight: #e5dece;
          --headerColor: #e5dece;
          --bodyTextColor: #4e4b66;
          --bodyTextColorWhite: #fafbfc;
          --topperFontSize: clamp(0.8125rem, 1.6vw, 1rem);
          --headerFontSize: clamp(1.9375rem, 3.9vw, 3.0625rem);
          --bodyFontSize: 1rem;
          --sectionPadding: clamp(3.75rem, 7.82vw, 6.25rem) 1rem;
     }
     
     #hp {
          font-family: "Solway", serif;
          padding: var(--sectionPadding);
     }

     #hp .hp-container {
          width: 100%;
          max-width: 80rem;
          margin: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: clamp(3rem, 6vw, 4rem);
     }

     #hp .hp-title {
          font-size: var(--headerFontSize);
          font-weight: 900;
          line-height: 1.2em;
          text-align: inherit;
          max-width: 24ch;
          margin: 0 0 1rem 0;
          color: var(--headerColor);
          position: relative;
     }

     #hp .hp-content {
          text-align: center;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
     }

     #hp .hp-topper {
          font-size: var(--topperFontSize);
          line-height: 1.2em;
          text-transform: uppercase;
          text-align: inherit;
          letter-spacing: 0.1em;
          font-weight: 700;
          color: var(--primary);
          margin-bottom: 10px;
          display: block;
     }

     .hp-text-container {
          width: 90%;
          max-width: 1000px;
          font-weight: 700;
          text-align: center;
          margin-top: -30px;
     }

     .hp-text-container h2 {
          color: var(--primary);
     }

     .hp-text-container h3 {
          text-align: center;
          margin-top: 50px;
          color: var(--headerColor);
     }

     .hp-text-container h2:nth-child(2) {
          margin-bottom: 50px;
     }
     
     .tickets-btn {
          background-color: #4c6d50;
          padding: 20px;
          text-decoration: none;
          color: #e5dece;
          border-radius: 20px;
     }
}

.schedule-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-wrap: wrap; /* Allows wrapping of sections on smaller screens */
}

.day-schedule {
  width: 48%; /* Makes each day take up half of the container */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 20px; /* Space between the two sections on mobile */
}

.day-title h2 {
  font-size: 30px !important;
  color: #5d4843 !important;
}

.day-title p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;

}

.events {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.event {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.event .time {
  font-weight: bold;
  color: #333;
}

.event .event-name {
  flex: 1;
  margin-left: 10px;
  color: #555;
}

.event:last-child {
  border-bottom: none;
}

.schedule-title {
     margin-top: -10px;
     color: #e2ca5d;
}

@media only screen and (max-width: 768px) {
     .friday-container,
     .saturday-container {
          width: 100%; /* Set to 100% width for mobile */
          display: block; /* Stack on top of each other */
          margin-bottom: 20px; /* Add margin between sections */
          background: #fff;
          padding: 20px;
          border-radius: 8px;
     }

     .friday-container {
          margin-left: 5px;
     }

     .schedule-container {
          flex-direction: column; /* Stack the sections vertically on mobile */
          align-items: center; /* Center the sections */
     }
      
        .day-schedule {
          width: 100% !important; /* Make each section take full width on mobile */
          margin-bottom: 15px !important; /* Add space between the sections */
     }
}

.flyer-container {
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     align-items: center;
     gap: 50px;
}

.con-flyer,
.host-ibro-flyer {
     margin-top: 20px;
}

.host-flyer h2 {
     color: var(--headerColor);
     width: 350px;
     margin: 0 auto;
     text-align: center;
     margin-top: 20px;
}

#site-link {
     font-size: large;
}

.con-flyer img {
     width: 300px;
}

.ibro-flyer img {
     margin-top: 10px;
     width: 300px;
     margin-left: 25px;
}

.ibro-flyer {
     max-width: 100%;
}

.dev-team-text-container {
     color: var(--primary);
     font-weight: 800;
     text-align: center;
}

.vendor-list {
     height: 444px;
}

.vendor-list h2 {
     color: var(--headerColor);
}

.vendor-list p {
     color: var(--primary);
     text-align: center;
}

.schedule-container {
     display: flex;
     justify-content: space-between;
     padding: 20px;
}
   
.day-schedule {
     width: 48%; /* Makes each day take up half of the container */
     padding: 10px;
     border: 1px solid #ccc;
     border-radius: 8px;
     background-color: #e5dece;
}
   
.day-title h2 {
     font-size: 1.5rem;
     color: #333;
}
   
.day-title p {
     font-size: 1rem;
     color: #555;
     margin-bottom: 10px;
}
   
.events {
     display: flex;
     flex-direction: column;
     gap: 10px;
}
   
.event {
     display: flex;
     justify-content: space-between;
     padding: 8px;
     border-bottom: 1px solid #ddd;
}
   
.schedule .time {
     font-weight: bold;
     color: #333;
}
   
.schedule .schedule-speaker {
     flex: 1;
     margin-left: 10px;
     color: #555;
}
   
.schedule:last-child {
     border-bottom: none;
}
   