@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Solway:wght@400;700;800&display=swap");

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #rsvp {
    --primary: #e2ca5d;
    --primaryLight: #415b44;
    --secondary: #a36700;
    --secondaryLight: #e5dece;
    --headerColor: #e5dece;
    --bodyTextColor: #28282B;
    --bodyTextColorWhite: #fafbfc;

    /* 31px - 49px */
    --headerFontSize: clamp(1.9375rem, 3.9vw, 3.0625rem);
    --bodyFontSize: 1rem;
    /* 60px - 100px top and bottom */
    --sectionPadding: clamp(3.75rem, 7.82vw, 6.25rem) 1rem;
  }
  
  #rsvp {
    font-family: "Solway", serif;
    padding: var(--sectionPadding);
  }
  #rsvp .rsvp-container {
    width: 100%;
    max-width: 80rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* 48px - 64px */
    gap: clamp(3rem, 6vw, 4rem);
  }
  #rsvp .rsvp-content {
    /* set text align to left if content needs to be left aligned */
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* centers content horizontally, set to flex-start to left align */
    align-items: center;
  }

  #rsvp .rsvp-title {
    font-size: var(--headerFontSize);
    font-weight: 900;
    line-height: 1.2em;
    text-align: inherit;
    /* max width of 24 characters including spaces */
    max-width: 24ch;
    margin: 0 0 1rem 0;
    color: var(--headerColor);
    position: relative;
  }

  #app-btn {
    border-radius: 15px 5px 5px 5px;
  }

  #agree-btn {
    border-radius: 5px 15px 5px 5px;
  }

  .vendor-btn {
    margin-top: 40px;
  }

  .vendor-btn button {
    padding: 15px;
    background-color: var(--primary);
    border-color: var(--headerColor);
    border-width: 1px;
    margin: 0.3px;
  }

  .app-container {
    background-color: var(--headerColor);
    width: 800px;
    margin-left: 520px;
    padding: 50px;
    border-radius: 50px;
  }

  .app-container h2 {
    color: var(--bodyTextColor);
    margin-bottom: 20px;
  }

  .app-container label {
    color: var(--bodyTextColor);
    margin: 15px;
  }

  .app-container input {
    border-radius: 5px;
    margin: 5px;
  }

  .app-container button {
    padding: 15px;
    background-color: var(--primary);
    border-width: 1px;
    border-radius: 50px;
    margin-top: 20px;
    margin-left: 20px;
  }

  .agree-container {
    background-color: var(--headerColor);
    width: 800px;
    margin-left: 520px;
    padding: 50px;
    border-radius: 50px;
  }

  .agree-container h2 {
    color: var(--bodyTextColor);
    margin-bottom: 20px;
  }

  .agree-container label {
    color: var(--bodyTextColor);
    margin: 15px;
  }

  .agree-container input {
    border-radius: 5px;
    margin: 5px;
  }
  
  .agree-container button {
    padding: 15px;
    background-color: var(--primary);
    border-width: 1px;
    border-radius: 50px;
    margin-top: 20px;
    margin-left: 20px;
  }

  .vendor-link-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vendor-link-btn button {
    padding: 15px;
    border: none;
    border-radius: 8px;
    background-color: #415b44;
    color: #e5dece;
  }

}

/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
  
}

.disclaimer {
  color: var(--primary);
  width: 90%;
  text-align: center;
  font-size: larger;
  margin-bottom: 40px;
}

/* Mobile */
@media (max-width: 480px) {
  .disclaimer {
    margin-top: -20px;
    margin-bottom: -40px;
  }

  .vendor-desc p {
    margin-top: 25px;
  }

}