@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Solway:wght@400;700;800&display=swap');

#tickets {
     --primary: #e2ca5d;
     --primaryLight: #415b44;
     --secondary: #a36700;
     --secondaryLight: #e5dece;
     --headerColor: #e5dece;
     --bodyTextColor: #4e4b66;
     --bodyTextColorWhite: #fafbfc;
     /* 13px - 16px */
     --topperFontSize: clamp(0.8125rem, 1.6vw, 1rem);
     /* 31px - 49px */
     --headerFontSize: clamp(1.9375rem, 3.9vw, 3.0625rem);
     --bodyFontSize: 1rem;
     /* 60px - 100px top and bottom */
     --sectionPadding: clamp(3.75rem, 7.82vw, 6.25rem) 1rem;
}

#tickets {
     font-family: "Solway", serif;
     padding: var(--sectionPadding);
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
}

.ticket-container {
     width: 100%;
     max-width: 80rem;
     display: flex;
     flex-direction: column;
     align-items: center;
}

#tickets .ticket-topper {
     font-size: var(--topperFontSize);
     width: 100%;
     line-height: 1.2em;
     text-transform: uppercase;
     text-align: center;
     letter-spacing: 0.1em;
     font-weight: 700;
     color: var(--primary);
     display: block;
}

.weekend-pass {
     width: 100%;
     text-align: center;
     margin-bottom: 50px;
     color: var(--primary);
     margin-top: 10px;
}

#tickets .ticket-title {
     font-size: var(--headerFontSize);
     width: 100%;
     font-weight: 900;
     line-height: 1.2em;
     text-align: center;
     /* max width of 24 characters including spaces */
     color: var(--headerColor);
     position: relative;
     margin-top: 10px;
}

.ticket-content p {
     color: red;
     text-align: center;
     margin-top: -30px;
     margin-bottom: 50px;
}



@media (min-width: 1500px) {
     .ticket-container {
          width: 40%;
     }

     .rsvp-text {
          margin-bottom: -40px;
     }
}