@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Solway:wght@400;700;800&display=swap");

@media only screen and (min-width: 0rem) {
#sponsor {
    --primary: #e2ca5d;
    --primaryLight: #415b44;
    --secondary: #a36700;
    --secondaryLight: #e5dece;
    --headerColor: #e5dece;
    --bodyTextColor: #000000c9;
    --bodyTextColorWhite: #fafbfc;
    --topperFontSize: clamp(0.8125rem, 1.6vw, 1rem);
    --headerFontSize: clamp(1.9375rem, 3.9vw, 3.0625rem);
    --bodyFontSize: 1rem;
    --sectionPadding: clamp(3.75rem, 7.82vw, 6.25rem) 1rem;
  }

  .sponsor-name {
    font-size: xx-large;
    margin-bottom: 15px;
    color: var(--bodyTextColor);
  }

  #sponsor {
    font-family: "Solway", serif;
    padding: var(--sectionPadding);
  }

  #sponsor .sponsors-container {
    width: 100%;
    max-width: 80rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(3rem, 6vw, 4rem);
  }

  #sponsor .sponsors-title {
    font-size: var(--headerFontSize);
    font-weight: 900;
    line-height: 1.2em;
    text-align: inherit;
    max-width: 24ch;
    margin: 0 0 1rem 0;
    color: var(--headerColor);
    position: relative;
  }

  #sponsor .sponsors-content {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #sponsor .sponsors-topper {
    font-size: var(--topperFontSize);
    line-height: 1.2em;
    text-transform: uppercase;
    text-align: inherit;
    letter-spacing: 0.1em;
    font-weight: 700;
    color: var(--primary);
    margin-bottom: 0.25rem;
    display: block;
  }

  .sponsors-text-container {
    width: 90%;
    max-width: 1000px;
    color: var(--headerColor);
    font-weight: 700;
    text-align: center;
  }

  .sponsors-text-container p {
    text-align: justify;
  }

  .sponsors-members-container h2 {
    color: var(--headerColor);
    font-size: xx-large;
    text-align: center;
    margin-bottom: 50px;
  }

  .sponsor-p {
    color: var(--headerColor);
  }

  .sponsor-card {
    color: 28282B;
  }

  .sponsor-card p {
    margin-bottom: 10px;
  }

  .sponsor-card-container {
    background-color: var(--headerColor);
    padding: 50px;
    margin: 40px;
    border-radius: 50px;
  }

  .sponsor-button {
    background-color: #415b44;
    padding: 10px;
    border-radius: 50px;
    text-decoration: none;
    color: var(--headerColor);
  }

  

  /* MEMBERS */

  .sponsors-member {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }

  .sponsors-members-container {
    width: 90%;
    max-width: 1000px;
  }

  .sponsors-members-content {
     display: flex;
     flex-direction: column;
     width: 100%;
     gap: 100px;
  }

  .sponsors-img-container {
    width: 200px;
    height: 200px;
  }

  .sponsors-img-container img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .sponsors-text {
    width: 75%;
    text-align: justify;
  }

  .sponsors-text h3 {
    margin-top: 0;
    color: var(--headerColor);
    font-weight: 900;
  }

  .sponsors-text p {
    margin: 0;
    color: var(--headerColor);
    font-weight: 700;
  }

  .schedule-container h2 {
    font-size: var(--headerFontSize);
    font-weight: 900;
    line-height: 1.2em;
    text-align: inherit;
    max-width: 24ch;
    margin: 0 0 1rem 0;
    color: var(--headerColor);
    margin-left: 80px;
  }

  .schedule-container li {
    color: var(--headerColor);
    font-weight: 700;
    font-size: large;
    margin: 50px;
  }

  .schedule-container {
    margin-left: 100px;
  }
}

@media screen and (max-width: 500px) {
  .sponsors-member {
    flex-direction: column;
  }

  .sponsors-text h3 {
    text-align: center;
  }

  .sponsors-text {
    width: 90%;
  }

  .schedule-container h2 {
    margin-left: 40px;
  }

  .schedule-container {
    margin-left: 0px;
  }

  .sponsor-card h4 {
    font-size: larger;
    font-weight: 700;
  }
}