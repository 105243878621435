@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Solway:wght@400;700;800&display=swap");

body {
  font-family: "Solway", serif;
}

@media only screen and (min-width: 0rem) {
#speaker {
    --primary: #e2ca5d;
    --primaryLight: #415b44;
    --secondary: #a36700;
    --secondaryLight: #e5dece;
    --headerColor: #e5dece;
    --bodyTextColor: #4e4b66;
    --bodyTextColorWhite: #fafbfc;
    --topperFontSize: clamp(0.8125rem, 1.6vw, 1rem);
    --headerFontSize: clamp(1.9375rem, 3.9vw, 3.0625rem);
    --bodyFontSize: 1rem;
    --sectionPadding: clamp(3.75rem, 7.82vw, 6.25rem) 1rem;
    padding: 40px;
  }

  #speaker {
    padding: 40px;
  }
  
  .speakers-container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  .speakers-content {
    margin-bottom: 20px;
  }
  
  .speakers-topper {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--primary);
  }
  
  .speakers-title {
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--headerColor)
  }
  
  .speakers-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .speaker-card {
    flex: 1 1 calc(33.33% - 20px); /* Adjusts width dynamically */
    max-width: 300px; /* Prevents oversized cards */
    background-color: var(--headerColor);
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .speaker-card:hover {
    transform: scale(1.05);
  }
  
  .speaker-img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .speaker-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #5d4843;
  }
  
  .speaker-description {
    font-size: 1rem;
    color: #5d4843;
  }

/* Speaker Schedule Wrapper (Centers the card below speakers) */
.schedule-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 20px;
}

/* Card Styling */
.schedule-card {
  background: #e5dece;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  text-align: center;
}

/* Schedule Title */
.speaker-schedule-title {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #5d4843;
  font-weight: bold;
}

/* Schedule List */
.schedules {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Individual Schedule Items */
.schedule {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.schedule .time {
  font-weight: bold;
  color: #333;
}

.schedule .schedule-name {
  flex: 1;
  text-align: right;
  font-weight: 500;
  color: #555;
}

}

/* Mobile Responsiveness */
/* Ensure the schedule name is centered properly on mobile */
@media (max-width: 480px) {
  .schedule {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .schedule .time {
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }

  .schedule .schedule-name {
    width: 100%;
    text-align: center;  /* Ensures the name is centered */
    font-size: 1rem;
    font-weight: 500;
    color: #555;
    margin-top: 5px;  /* Adds spacing for better readability */
  }
}

  
  /* Adjust layout for smaller screens */
  @media (max-width: 768px) {
    .speaker-card {
      flex: 1 1 calc(50% - 20px); /* Two cards per row */
    }
  }
  
  @media (max-width: 480px) {
    .speaker-card {
      flex: 1 1 100%; /* Full width on smaller screens */
    }
  }
