@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Solway:wght@400;700;800&display=swap');

.fireside {
    --primary: #e2ca5d;
    --primaryLight: #415b44;
    --secondary: #a36700;
    --secondaryLight: #e5dece;
    --headerColor: #e5dece;
    --bodyTextColor: #000000c9;
    --bodyTextColorWhite: #fafbfc;
    --topperFontSize: clamp(0.8125rem, 1.6vw, 1rem);
    --headerFontSize: clamp(1.9375rem, 3.9vw, 3.0625rem);
    --bodyFontSize: 1rem;
    --sectionPadding: clamp(3.75rem, 7.82vw, 6.25rem) 1rem;
}

.fireside {
    font-family: "Solway", serif;
    padding: var(--sectionPadding);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.fireside-container {
    width: 100%;
    max-width: 80rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fireside .fireside-topper {
    font-size: var(--topperFontSize);
    width: 100%;
    line-height: 1.2em;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.1em;
    font-weight: 700;
    color: var(--primary);
    display: block;
}

.fireside .fireside-title {
    font-size: var(--headerFontSize);
    width: 100%;
    font-weight: 900;
    line-height: 1.2em;
    text-align: center;
    /* max width of 24 characters including spaces */
    color: var(--headerColor);
    position: relative;
    margin-top: 10px;
}

.fireside-text {
    color: var(--headerColor);
    margin-top: 50px;
    justify-content: center;
    font-size: 1.2rem;
}

.sold-out {
    text-align: center;
    color: red;
    font-size: large;
    margin-top: 40px;
}

@media (max-width: 480px) {
    .fireside-text {
        width: 90%;
        align-items: center;
        margin-left: 20px;
    }
}